import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['body']

  static values = {
    hideLinks: Boolean
  }

  connect() {
    this.highlightMentionsOfCurrentUser()
    this.insertLinksToCommunityProfiles()
  }

  highlightMentionsOfCurrentUser() {
    const currentUserId = this.bodyTarget.dataset.highlitedId

    if (currentUserId) {
      const items = this.bodyTarget.querySelectorAll(`span.mention[data-id='${currentUserId}']`)

      for (const item of items) {
        item.classList.add('highlighted-mention')
      }
    }
  }

  insertLinksToCommunityProfiles() {
    let tagAttrs = 'style="text-decoration: none;" data-turbo-action="advance" data-turbo-frame="community_content"'

    if (this.hideLinks) {
      tagAttrs = tagAttrs.concat(' ', 'class="pointer-events-none"')
    }

    if (this.bodyTarget.dataset.adminLink) {
      this.bodyTarget.querySelectorAll('span.mention').forEach(node => {
        node.innerHTML = `<a href="/bullet/community/people/${node.dataset.id}/general_info" target='_blank'">${node.textContent}</a>`
      })
    } else {
      this.bodyTarget.querySelectorAll('span.mention').forEach(node => {
        node.innerHTML = `<a href="/community/profiles/${node.dataset.id}" ${tagAttrs}>${node.textContent}</a>`
      })
    }


  }
}
